import React from "react"
import { Link } from "gatsby"
import "./FaqCta.scss"

// import XmasBG from "-!svg-react-loader?props[]=className:santa-logo!../../img/bg.svg"


const FaqCta = () => {

    return (
        <section class="hero xmas-bg is-medium is-primary">
            {/* <XmasBG /> */}
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-vcentered">
                        <div class="is-paddingless column has-text-centered">
                            <p className="subtitle is-size-5 is-uppercase has-text-weight-bold">View our holiday packages</p>
                            <p class="title is-size-2 is-size-3-touch has-text-weight-bold">Choose your Santa<br></br>Ring experience.</p>
                            <Link className="button is-large is-primary" to="/packages/">
                                View Packages
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqCta