import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

import IntroHeading from "../components/IntroHeading/IntroHeading"
import IntroSteps from "../components/IntroSteps/IntroSteps"
import FaqCta from "../components/FaqCta/FaqCta"

class HomePage extends Component {
  render() {
    return (
      <Layout>
        <div className="home-container">
          <Helmet title={`Virtual Santa | ${config.siteTitle}`} />
          <div className="body-container">
            {/* <IntroHeading /> */}
            {/* <IntroSteps /> */}
            <FaqCta />
            <section className="hero is-small is-white has-background">
                <div className="hero-body">
                    <div className="container">
                    </div>
                </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default HomePage;
